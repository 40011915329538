// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap';
import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "../stylesheets/application.scss"
import "../stylesheets/home_page"
import "../stylesheets/user/_user"
import fileUpload from 'fileUpload'



// listen on 'turbolinks:load' instead of 'DOMContentLoaded' if using Turbolinks
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.upload-file').forEach(fileInput => {
    fileUpload(fileInput)
  })
})




document.addEventListener("turbolinks:load", () => {

  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})


const images = require.context('../images', true)
const imagePath = (name) => images(name, true)